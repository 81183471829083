import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import theme, { WEB } from "../theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import verified from "../assets/verified.png";
import AdminVerify from "./AdminVerify";
export default function Dashboard() {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const [clients, setClientData] = useState(null);
  const [userstatus, setuserStatus] = useState(null);
  const [sortBy, setSortBy] = useState("status"); // Default sorting by name
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order
  const [showModal, setShowModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [count, setCount] = useState(0);

  const handleUpdateClick = (client) => {
    navigate(`/updateadmin/${client?.userId}`, {
      state: { clientData: client },
    });
  };
  const handleDeleteClick = async (client) => {
    try {
      const response = await axios.post(
        `${WEB.Backend}node/delete/${clientToDelete.userId}`
      );

      if (response.data.message) {
        setClientData((prevClients) =>
          prevClients.filter((c) => c.userId !== clientToDelete.userId)
        );
        toast.success(response.data.message);
        setCount(count - 1);
        setShowModal(false);
      }
    } catch (error) {
      toast.error("Something failed Please Try Again");
    }
  };

  const GetUser = () => {
    const users = localStorage.getItem("user");
    const data = JSON.parse(users);
    if (data) {
      setUser(data);
      const userId = data && data.userId;
      fetch(`${WEB.Backend}node/getusers`)
        .then((response) => response.json())

        .then((data2) => {
          setClientData(data2.data);
          console.log(data2.data);
          setCount(data2.data.length);
        })

        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  useEffect(() => {
    GetUser();
  }, []);

  useEffect(() => {
    GetUser();
  }, []);
  const handleLoginAsClick = (client) => {
    // Perform login action using the credentials of the selected client
    // For example, you can store the client's information in localStorage
    localStorage.setItem("user", JSON.stringify(client));

    // Redirect or perform any actions needed after logging in as the client
    // For example, redirect to a different page
    navigate("/dashboard"); // Replace '/dashboard' with the desired URL
  };
  const handlenewStatusClick = (client) => {
    console.log(client.name);
    console.log(client.userId);

    const data = {
      status: client.status == "new" ? "enabled" : "disabled",
    };
    axios
      .put(`${WEB.Backend}node/updateuserstatus/${client.userId}`, data)
      .then((response) => {
        console.log(response.data.message);
        toast.success(response.data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        toast.error("Something failed. Please try again.");
      });
  };
  const handleStatusClick = (client) => {
    console.log(client.name);
    console.log(client.userId);

    const data = {
      status: client.status == "disabled" ? "enabled" : "disabled",
    };
    axios
      .put(`${WEB.Backend}node/updateuserstatus/${client.userId}`, data)
      .then((response) => {
        console.log(response.data.message);
        toast.success(response.data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        toast.error("Something failed. Please try again.");
      });
  };

  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
  };
  const handleOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const sortedClients = clients ? [...clients] : [];
  sortedClients.sort((a, b) => {
    const compareValue = (valueA, valueB) => {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    const orderMultiplier = sortOrder === "asc" ? 1 : -1;

    switch (sortBy) {
      case "name":
        return compareValue(a.name, b.name) * orderMultiplier;
      case "number":
        return compareValue(a.phone, b.phone) * orderMultiplier;
      case "role":
        return compareValue(a.role, b.role) * orderMultiplier;
      case "status":
        return compareValue(a.status, b.status) * orderMultiplier;
      case "verified":
        return compareValue(a.verified, b.verified) * orderMultiplier;
      default:
        return 0;
    }
  });
  const handleShowModal = (client) => {
    setShowModal(true);
    setClientToDelete(client);
  };
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <Navbar counts={count} />
      <div className="container">
        <div className="container bg-light">
          <div style={theme.ROW}>
            <div
              className="col mb-3 bg-light text-dark p-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 40,
              }}
            >
              <div className="col-2">
                <label>Photo</label>
              </div>
              <div className="col-2">
                <label>Name</label>
              </div>
              <div className="col-2">
                <label>Role</label>
              </div>
              <div className="col-2">
                <label>Status</label>
              </div>
              <div className="col-2">
                <label>Phone</label>
              </div>
            </div>
          </div>
          <div style={theme.ROW}>
            <h6 className="ms-3 text-dark">
              Welcome Admin {user && user.name}
            </h6>
            <p className="ms-3 text-dark">
              Total Users :{" "}
              {clients && clients.length > 0 ? clients.length - 1 : "No Users"}
            </p>
          </div>
          <div style={theme.ROW}>
            <label className="ms-3 text-dark">Sort by:</label>
            <select
              className="form-select"
              value={sortBy}
              onChange={handleSortChange}
            >
              <option value="status">Status</option>
              <option value="name">Name</option>
              <option value="number">Number</option>
              <option value="role">Role</option>
              <option value="verified">Verification</option>
            </select>
            <button className="btn btn-dark" onClick={handleOrderChange}>
              {sortOrder === "asc" ? "Asc" : "Desc"}
            </button>
          </div>

          <div>
            <AdminVerify />
          </div>

          {clients &&
            sortedClients.map((client) => (
              <>
                {client.adminrole !== "superadmin" && (
                  
                  <div key={client.clientid}>
                    <div className="col mb-3">
                    {client.status =="new"&&<div style={{backgroundColor:'green',padding:5,borderRadius:10,flexDirection:'row',justifyContent:'center',alignItems:'center',display:'flex',marginTop:10}}><h6 style={{color:'white'}}>New Profile</h6></div>}
                    {client.status =="disabled"&&<div style={{backgroundColor:'red',padding:5,borderRadius:10,flexDirection:'row',justifyContent:'center',alignItems:'center',display:'flex',marginTop:10}}><h6 style={{color:'white'}}>Profile Freezed</h6></div>}
                      <div className="d-flex m-1">
                        <div>
                          {client.avatar !== null && (
                            <img
                              style={{ width: "90px", height: "100%" }}
                              className="img-fluid"
                              src={`${WEB.Backend}node/uploads/${client.avatar}`}
                            />
                          )}
                        </div>
                        <div className="col">
                          <div
                            className="col bg-light text-dark p-2 rounded-2"
                            style={{ height: 100 }}
                          >
                            <div
                              className="m-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client.name}
                                  {client?.verified === "yes" ? (
                                    <img
                                      src={verified}
                                      height={20}
                                      width={20}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>

                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client?.role}
                                </p>
                              </div>
                            </div>
                            <div
                              className="m-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client?.phone}
                                </p>
                              </div>
                              <div className="col">
                                <p style={{ fontSize: 14, fontWeight: "bold" }}>
                                  {client?.city}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="col text-dark  rounded-2"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className="col">
                            {client?.status=='new'?<button
                              className={
                                client?.status == "enabled"
                                  ? "col  m-1 btn btn-dark"
                                  : "col  m-1 btn btn-success"
                              }
                              onClick={() => handlenewStatusClick(client)}
                            >
                              {client?.status == "new" ? "Enable" : "Freeze"}
                            </button>:<button
                              className={
                                client?.status == "enabled"
                                  ? "col  m-1 btn btn-dark"
                                  : "col  m-1 btn btn-success"
                              }
                              onClick={() => handleStatusClick(client)}
                            >
                              {client?.status == "disabled" ? "UnFreeze" : "Freeze"}
                            </button>}

                            <button
                              className="col  m-1 btn btn-warning"
                              onClick={() => handleUpdateClick(client)}
                            >
                              <FontAwesomeIcon icon={"edit"} />
                              Edit
                            </button>
                            {/* <button className='col  m-1 btn btn-primary' onClick={() => handleLoginAsClick(client)}>Login As</button> */}
                            <button
                              className="col  m-1 btn btn-danger"
                              style={{ color: "white" }}
                              onClick={() => handleShowModal(client)}
                            >
                              <FontAwesomeIcon color="white" icon={"trash"} />{" "}
                              Delete
                            </button>
                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Profile</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <p>Do you want to delete this profile ?</p>
                                  <p>
                                    Name:{clientToDelete && clientToDelete.name}{" "}
                                  </p>
                                  <p>
                                    Phone:
                                    {clientToDelete && clientToDelete.phone}{" "}
                                  </p>
                                </div>
                                <div className="row p-3">
                                  <button
                                    className="btn btn-danger  col"
                                    style={{ color: "white" }}
                                    onClick={() => handleDeleteClick(client)}
                                  >
                                    Yes
                                  </button>
                                </div>
                                <div className="row p-3">
                                  <button
                                    className="btn  col"
                                    style={{
                                      backgroundColor: theme.COLORS.PRIMARY,
                                      color: "white",
                                    }}
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>{" "}
      </div>{" "}
    </>
  );
}
