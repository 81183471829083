import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import THEME, { WEB } from '../theme';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/1 (2).jpg'; // Your logo
import introImage from '../assets/verified.png'; // Placeholder for the intro section
import applogin from '../assets/applogin.jpg'; // Placeholder for feature 1
import regimage from '../assets/appreg.jpg'; // Placeholder for feature 2
import appwelcome from '../assets/appwelcome.jpg'; // Placeholder for feature 3
import appsearch from '../assets/appsearch.jpg'; // Placeholder for how to use
import appdetails1 from '../assets/appdetails1.jpg'; // Placeholder for how to use
import appdetails2 from '../assets/appdetails2.jpg'; // Placeholder for how to use
import appchat from '../assets/appchat.jpg'; // Partner logo
import sk1 from '../assets/e.png'; // Placeholder for how to use
import sk2 from '../assets/b.png'; // Placeholder for how to use
import sk3 from '../assets/c.png'; // Placeholder for how to use
import sk4 from '../assets/d.png'; // Partner logo
import appchat2 from '../assets/appchat2.jpg'; // Partner logo
import appprofile from '../assets/appprofile.jpg'; // Partner logo
import appmmlist from '../assets/appmmlist.jpg'; // Partner logo
import appprofilever from '../assets/appver.jpg'; // Partner logo
import appnew from '../assets/appnew.jpg'; // Partner logo
import '../styles.css'; // Custom styles for extra polish and responsive tweaks
import Navbar from './Navbar';

const Download = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="download-page">
        <ToastContainer />
<div>
<header className="header-section text-center py-4" style={{ background: `linear-gradient(45deg, #e43d30, #0672ad)` }}>

<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
  <div><img className="logo-img ms-3 me-3" src={logo} alt="Logo" style={{height:100,width:100}} /></div>
  <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
    <div><h3 className="app-title  text-white"style={{fontFamily:'serif'}}>SK Matrimonial</h3>
  <p className="lead text-light " style={{fontSize:14}}>Match Makers Heaven - WorldWide</p></div>
  </div>
</div>
</header>
</div>
      {/* Header Section */}
             
        <div className="bg-white p-3" style={{textAlign:'center',alignItems:'center',justifyContent:'space-evenly',display:'flex',flexDirection:'row'}}>
         <h6 className="text-dark">Get the SK Matrimonial App Now! Updated on 25/9/2024</h6><a
            href="https://skmatrimonial.com/SKMatrimonial.apk"
            className="btn btn-md btn-success "
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://skmatrimonial.com/SKMatrimonial.apk';
            }}
          >
            Download Now
          </a>
      
        </div>
      



      {/* Features Section */}
      <section className="bg-light">
        <div className="container">
          <h2 className="text-center mb-4 section-title">Proud Presentations of SK World of Match Makers</h2>
         
          <div className="row p-5">
            <div className="col-4 text-center">
   
              
              <a href="https://sumairakamil.com"><img src={sk1} className="img-fluid rounded shadow-5 mb-2" /></a>
              <a href='https://sumairakamil.com'><h6 style={{color:'blue'}}>Sumaira Kamil</h6></a>
                  </div>
            <div className="col-4 text-center">
           
            <a href="https://skwelfare.pk"><img src={sk3}  className="img-fluid rounded shadow-5 mb-2" /></a>
            <a href='https://skwelfare.pk'><h6 style={{color:'blue'}}>SK Welfare</h6></a>
              

            </div>
            <div className="col-4 text-center">

            <a href="https://skmatrimonials.com"><img src={sk2} className="img-fluid rounded shadow-5 mb-2" /></a>
            <a href='https://skmatrimonials.com'><h6 style={{color:'blue'}}>SK Matrimonial International</h6></a>
            
              
              </div>
            
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section className="features-section py-4 bg-light">
        <div className="container">
          <h3 className="text-center mb-4 section-title">App Features</h3>
          <p className="section-description text-dark text-center">
            SK Matrimonial connects you with verified profiles based on your preferences. With a user-friendly interface and AI-driven matchmaking, finding your life partner has never been easier!
          </p>
          <div className="row text-center p-5">
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <h3 className="feature-title mt-2">Verified Profiles</h3>
              <img src={appdetails1} alt="Feature 1" className="img-fluid rounded shadow-5 mb-2" />
              
              <p className="feature-text">All profiles are verified for authenticity, ensuring safe and reliable connections.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <h3 className="feature-title mt-2">Smart Search</h3>
              <img src={appsearch} alt="Feature 2" className="img-fluid rounded shadow-5 mb-2" />
           
              <p className="feature-text">Use advanced filters like religion, caste, location, and interests to find your ideal match.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <h3 className="feature-title mt-2">Fast Chat</h3>
              <img src={appchat} alt="Feature 3" className="img-fluid rounded shadow-5 mb-2" />
            
              <p className="feature-text">Our algorithm suggests the most compatible profiles based on your preferences.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <h3 className="feature-title mt-2">Profile Verification</h3>
            <img src={appprofilever} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
            
              <p className="how-to-text">Fill in your profile with relevant information to find the best matches.</p>
            </div>
          </div>
        </div>
      </section>

     

      {/* How to Use Section */}
      <section className="features-section py-4 bg-light">
      <div className="container">
          <h3 className="text-center mb-4 section-title">How to Use the App</h3>
          <div className="row text-center p-5">
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
              <img src={regimage} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Create an Account</h6>
              <p className="how-to-text">Sign up with your details to get started on your journey.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <img src={applogin} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Login With Valid ID</h6>
              <p className="how-to-text">Fill in your profile with relevant information to find the best matches.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <img src={appwelcome} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Welcome Screen</h6>
              <p className="how-to-text">User Home Page here you find all the tools related to matchmaking process.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5 p-2">
            <img src={appsearch} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Start Matching</h6>
              <p className="how-to-text">Browse profiles and connect with potential life partners.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="partners-section py-4 bg-light">
        <div className="container">
          <div className="row text-center p-5">
          <div className="col-6 col-md-3 mb-4 shadow-5">
              <img src={appnew} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Add New Profile</h6>
              <p className="how-to-text">Create your own profiles.</p>
            </div>
           
            <div className="col-6 col-md-3 mb-4 shadow-5">
            <img src={appprofile} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Change Settings</h6>
              <p className="how-to-text">Change your Account Settings.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5">
            <img src={appprofilever} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Verify Your Account</h6>
              <p className="how-to-text">Get Verified your Account.</p>
            </div>
            <div className="col-6 col-md-3 mb-4 shadow-5">
            <img src={appmmlist} alt="How to Use 1" className="img-fluid rounded shadow-5 mb-2" />
              <h6 className="how-to-title mt-2">Benefits of Verification</h6>
              <p className="how-to-text">Verified Matchmakers can view the list of All Matchmakers.</p>
            </div>
            
          </div>
        </div>
      </section>

   
    </div>
  );
};

export default Download;
