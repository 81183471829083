import React, { useState } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import THEME,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/1 (1).jpg';
import Search from './Search';
import { useAuth } from './AuthContext';


const Login = () => {
  const { logout,user } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().required('Phone is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        // await axios.post('https://worldmuslimsmatches.com/node/login', values);
       const data= await axios.post(`${WEB.Backend}node/login`, values);
        
        const userData=data.data.data
        
        if(data.data.data.status!=="enabled"){
        
          logout()  
          return toast.error("Account isn't Approved Yet Please Contact Admin")
      }else if(userData &&userData.role=="admin"){
        toast.success(data.data.message);
        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/admindashboard'); // Use the route for AdminDashboard
      }else{
        toast.error("Login with correct Admin Phone/Password");
      }
        
       
  
      } catch (error) {
        console.error('Login error: ', error);
        toast.error(error.response.data.error);
      }
    },
  });

  

  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (<div className='container pt-3 pb-5'>
    <ToastContainer/>
    <div className="container rounded-2 bg-light col-md-6 pt-5 pb-5">
        <div className="col"style={THEME.Center}>
     
             <div className="col-md-6 col-lg-4 pb-5 mb-1 text-center">
        <img className='rounded me-2' style={{width:70,height:70}}src={logo}></img>
        <h5 style={{fontWeight:'bold',color:THEME.COLORS.PRIMARY}}>SK MATRIMONIALS</h5></div>
            <h3 style={{color:THEME.COLORS.PRIMARY}} className="text-center mb-4">SK Admin Login here</h3>


            <form onSubmit={formik.handleSubmit}>
    
    <input className="mt-2 text-center  form-control" placeholder="Phone" type="number" {...formik.getFieldProps('phone')} />
    {formik.touched.phone && formik.errors.phone && <div>{formik.errors.phone}</div>}

    <input className="mt-2  text-center  form-control" placeholder="Password" type="password" {...formik.getFieldProps('password')} />
    {formik.touched.password && formik.errors.password && <div>{formik.errors.password}</div>}
<div className=''style={THEME.Centerflex}>
<button style={{backgroundColor:THEME.COLORS.PRIMARY,color:'white'}} className=' col-5 btn m-3' type="submit">Login</button></div>

  </form>
            
            
        
     </div>
                   
        </div>
     
    </div>
);
};

export default Login;
