import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import THEME,{WEB} from "../theme";
import { toast, ToastContainer } from "react-toastify";
import { countries, simulatedCities } from "./custom/LocationData"
import SelectCode from './custom/Select'


import "react-toastify/dist/ReactToastify.css";


  const validationSchema = Yup.object({
    gender: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    age: Yup.number().required("Required").positive("Must be a positive number"),
  });







const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = location.state && location.state.clientData;
  
  const [user, setUserData] = useState(location.state.clientData);
  const [provinces, setProvinces] = useState([]);
  const [reqprovinces, setreqProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [reqcities, setreqCities] = useState([]);
  const [othercity, setothercity] = useState(false);
  const [otherreqcity, setotherreqcity] = useState(false);



  
  useEffect(() => {
    if (location.state && location.state.clientData) {
      setUserData(location.state.clientData);
    } else {
     
    }
  }, [location.state]);

  const userId = user.userId;
  const [isEditing, setIsEditing] = useState(true);


  const formik = useFormik({
    
    initialValues :{  
      matchmakerid:client.matchmakerid,
      role:client.role,
      gender: client.gender,
      name: client.name,
      age: client.age,
      height: client.height,
      ccode: client.ccode,
      contact: client.contact,
      cast: client.cast,
      maslak: client.maslak,
      complexion: client.complexion,
      maritalstatus: client.maritalstatus,
      divorcereason: client.divorcereason,
      children: client.children,
      education: client.education,
      job: client.job,
      business: client.business,
      income: client.income,
      language: client.language,
      belongs: client.belongs,
      country: client.country,
      province: client.province,
      city: client.city,
      area: client.area,
      familystatus:client.familystatus,
      fulladdress: client.fulladdress,
      hometype: client.hometype,
      homesize: client.homesize,
      father: client.father,
      mother: client.mother,
      brothers: client.brothers,
      sisters: client.sisters,
      reqage: client.reqage,
      reqageto: client.reqageto,
      reqcast: client.reqcast,
      reqmaslak: client.reqmaslak,
      reqheight: client.reqheight,
      reqfamilystatus: client.reqfamilystatus,
      reqmaritalstatus: client.reqmaritalstatus,
      reqeducation: client.reqeducation,
      reqcity: client.reqcity,
      reqprovince: client.reqprovince,
      reqcountry: client.reqcountry,
      proptype: client.proptype,
      shareamount: client.shareamount,
      amount: client.amount,
      propfor: client.propfor,
      propprio: client.propprio,
      description: client.description,
      avatar:client.avatar,
      photo1:client.photo1,
      photo2:client.photo2},
      validationSchema,
    onSubmit: async (values) => {
      // Update user information
      axios
        .put(`${WEB.Backend}node/clients/update/${client.clientid}`, values)
        .then((response) => {
          console.log(response.data.message);
          toast.success(response.data.message);
          console.log(response)
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          toast.error("Something failed. Please try again.");
        });
    },
  });

  const handleFileChange = (e) => {
    formik.setFieldValue("avatar", e.target.files[0]);
  };

  
  const imageupdate=async(values)=>{
       
    try {
        const formData = new FormData();

        formData.append('avatar', values.avatar[0]?values.avatar[0]:[]);
        formData.append('photo1', values.photo1[0]?values.photo1[0]:[]);
        formData.append('photo2', values.photo2[0]?values.photo2[0]:[]);


        const response = await axios.put(`${WEB.Backend}node/clients/update/images/${client.clientid}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success(response.data.message);
        navigate('/dashboard')
        setIsEditing(false);
    } catch (error) {
        toast.error("Image Update Failed");
        console.error("Registration error: ", error.response.data.error);
      }


// Handle form submission (update the records with new values)
console.log("Form submitted with values:", values);

  }
  const deletephotos=async()=>{
       
    try {
        const formData = new FormData();

        formData.append('avatar', []);
        formData.append('photo1', []);
        formData.append('photo2', []);


        const response = await axios.put(`${WEB.Backend}node/clients/update/images/${client.clientid}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success(response.data.message);
        navigate('/dashboard')
        setIsEditing(false);
    } catch (error) {
        toast.error("Something failed Please Try Again");
        console.error("Registration error: ", error.response.data.error);
      }



  }
    
  const handleCountryChange = selectedCountry => {
    const selectedCountryObj = countries.find(country => country.name === selectedCountry);

    if (selectedCountryObj) {
      setProvinces(selectedCountryObj.provinces || []);
      setCities([]);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
    }
  };
  const handleProvinceChange = selectedProvince => {
    // Simulate fetching cities data based on the selected province
    // In a real-world scenario, you would need a proper API that provides this data

    setCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue('city', '');
  };

  
  const handlereqCountryChange = selectedCountry => {
    const selectedCountryObj = countries.find(country => country.name === selectedCountry);

    if (selectedCountryObj) {
      setreqProvinces(selectedCountryObj.provinces || []);
      setreqCities([]);
      formik.setFieldValue('reqprovince', '');
      formik.setFieldValue('reqcity', '');
    }
  };

    const handlereqProvinceChange = selectedProvince => {
    // Simulate fetching cities data based on the selected province
    // In a real-world scenario, you would need a proper API that provides this data

    setreqCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue('reqcity', '');
  };
  return (
    <>
<Navbar/>
              

       
    <div className="container bg-light text-dark">
    <h3 className="mb-1 pt-5">Photos</h3>
        
        <div>
        <div className="col m-1"><label htmlFor="avatar">Photo:</label>
         <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('avatar', event.currentTarget.files)} />
 </div>
 
         <div className="col m-1"><label htmlFor="photo1">Photo 2:</label>
         <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('photo1', event.currentTarget.files)} />
 </div>
 <div className="col m-1"><label htmlFor="photo2">Photo 3:</label>
         <input type="file" className="col form-control" onChange={(event) => formik.setFieldValue('photo2', event.currentTarget.files)} />
 </div>
 <button
           className="col-5  btn btn-dark text-light m-2"
           onClick={isEditing ? ()=>{imageupdate(formik.values)} : null}
         >
         {isEditing ? "Update Photos" : "Change Photos"}
       </button>
       <button
           className="col-5  btn btn-danger m-2"
           onClick={deletephotos}
         >
         Delete Photos
       </button>
 </div>
 <h3 className="mb-1 pt-5">Proposal Details</h3>
       <div style={THEME.ROW}>
         <div className="m-1 col-3">
          <label className="form-label">Type</label>
  <select
    className=" form-control"
    {...formik.getFieldProps("proptype")}
  ><option value="" label="" />
    <option value="Own" label="Own" />
    <option value="Sharing Local" label="Sharing Local" />
    <option value="Sharing Abroad" label="Sharing Abroad" />

  </select>
 
          {formik.touched.proptype && formik.errors.proptype && (
            <div className="text-dark">{formik.errors.proptype}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Sharing Type</label>
          <select
    className=" form-control"
    {...formik.getFieldProps("shareamount")}
  ><option value="" label="" />
    <option value="No Sharing" label="No Sharing" />
    <option value="Will Give Sharing" label="Will Give Sharing" />
    <option value="Will Get Sharing" label="Will Get Sharing" />

  </select>
           {formik.touched.shareamount && formik.errors.shareamount && (
            <div className="text-dark">{formik.errors.shareamount}</div>
          )}
        </div>
        <div className="m-1 col-3">
          <label className="form-label">Amount</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("amount")}
          />
          {formik.touched.amount && formik.errors.amount && (
            <div className="text-dark">{formik.errors.amount}</div>
          )}
        </div>
</div>
       <div style={THEME.ROW}>
        <div className="m-1 col">
          <label className="form-label">Proposal Origin</label>
          <select
    className=" form-control"
    {...formik.getFieldProps("propfor")}
  ><option value="" label="" />
    <option value="Local To Local" label="Local To Local" />
    <option value="Local To Abroad" label="Local To Abroad" />
    <option value="Abroad To Local" label="Abroad To Local" />
    <option value="Abroad To Abroad" label="Abroad To Abroad" />
    <option value="Both Local & Abroad" label="Both Local & Abroad" />
  </select>
 
          {formik.touched.propfor && formik.errors.propfor && (
            <div className="text-dark">{formik.errors.propfor}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Proposal Priority</label>
          <select
    className=" form-control"
    {...formik.getFieldProps("propprio")}
  >
    <option value="" label="" />
    <option value="Low" label="Low" />
    <option value="Normal" label="Normal" />
    <option value="Urgent" label="Urgent" />
    <option value="Very Urgent" label="Very Urgent" />
  </select>
 
          {formik.touched.propprio && formik.errors.propprio && (
            <div className="text-dark">{formik.errors.propprio}</div>
          )}
        </div>
       </div>

      <h3 className="mb-1 pt-5">Personal Details</h3>
      <form onSubmit={formik.handleSubmit}>
       
       
       <div style={THEME.ROW}>
        <div className="m-1" hidden>
          <label className="form-label">Matchmaker ID</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("matchmakerid")}
          />
          {formik.touched.matchmakerid && formik.errors.matchmakerid && (
            <div className="text-dark">{formik.errors.matchmakerid}</div>
          )}
        </div>

        <div className="m-1 col">
  <label className="form-label">Gender</label>
  <select
    className=" form-control"
    {...formik.getFieldProps("gender")}
  >
    <option value="" label="" />
    <option value="male" label="Male" />
    <option value="female" label="Female" />
  </select>
  {formik.touched.gender && formik.errors.gender && (
    <div className="text-dark">{formik.errors.gender}</div>
  )}
</div>

<div className="m-1 col">
          <label className="form-label">Name</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-dark">{formik.errors.name}</div>
          )}
        </div>
        <div className="m-1 col-2">
          <label className="form-label">Age</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("age")}
          />
          {formik.touched.age && formik.errors.age && (
            <div className="text-dark">{formik.errors.age}</div>
          )}
        </div>
</div>
       <div style={THEME.ROW}>  
        <div className="m-1 col-2">
          <label className="form-label">Height</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("height")}
          />
          {formik.touched.height && formik.errors.height && (
            <div className="text-dark">{formik.errors.height}</div>
          )}
        </div>
        <div hidden className="m-1">
          <label className="form-label">Contact</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("contact")}
          />
          {formik.touched.contact && formik.errors.contact && (
            <div className="text-dark">{formik.errors.contact}</div>
          )}
        </div>
        <div hidden className="m-1">
          <label className="form-label">Country Code</label>
          <SelectCode
            type="text"
            className=" form-control"
            {...formik.getFieldProps("ccode")}
          />
          {formik.touched.ccode && formik.errors.ccode && (
            <div className="text-dark">{formik.errors.ccode}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Cast</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("cast")}
          />
          {formik.touched.cast && formik.errors.cast && (
            <div className="text-dark">{formik.errors.cast}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Maslak</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("maslak")}
          />
          {formik.touched.maslak && formik.errors.maslak && (
            <div className="text-dark">{formik.errors.maslak}</div>
          )}
        </div>
        </div>
        <div style={THEME.ROW}>
       <div className="m-1 col">
  <label className="form-label">Marital Status</label>
  <select
    className=" form-control"
    {...formik.getFieldProps("maritalstatus")}
  >
    <option value="" label="" />
    <option value="single" label="Single Never Merried" />
    <option value="nikkahbreak" label="Nikkah Break" />
    <option value="divorced" label="Divorced" />
    <option value="divorcedchild" label="Divorced (With Childrens)" />
    <option value="widowed" label="Widowed" />
    <option value="separated" label="Separated" />
    <option value="Second Marriage" label="Second Marriage" />
  </select>
  {formik.touched.maritalstatus && formik.errors.maritalstatus && (
    <div className="text-dark">{formik.errors.maritalstatus}</div>
  )}
</div>

{formik.values.maritalstatus === 'divorced'||formik.values.maritalstatus ==='divorcedchild' ? (<>
  <div className="m-1 col"><div className="m-1" >
          <label className="form-label">Reason</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("divorcereason")}
          />
          {formik.touched.divorcereason && formik.errors.divorcereason && (
            <div className="text-dark">{formik.errors.divorcereason}</div>
          )}
        </div></div>
         </>
):null}
{formik.values.maritalstatus === 'divorcedchild' && (<><div className="m-1 col"><div className="m-1">
          <label className="form-label">Childrens</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("children")}
          />
          {formik.touched.children && formik.errors.children && (
            <div className="text-dark">{formik.errors.children}</div>
          )}
        </div></div>

         </>
)}
        
</div>
       <div style={THEME.ROW}>
        
        
        <div className="m-1">
          <label className="form-label">Complexion</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("complexion")}
          />
          {formik.touched.complexion && formik.errors.complexion && (
            <div className="text-dark">{formik.errors.complexion}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Education</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("education")}
          />
          {formik.touched.education && formik.errors.education && (
            <div className="text-dark">{formik.errors.education}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Language</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("language")}
          />
          {formik.touched.language && formik.errors.language && (
            <div className="text-dark">{formik.errors.language}</div>
          )}
        </div>
</div>
       
       <div style={THEME.ROW}>
        <div className="m-1 col">
          <label className="form-label">Job</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("job")}
          />
          {formik.touched.job && formik.errors.job && (
            <div className="text-dark">{formik.errors.job}</div>
          )}
        </div>
        <div className="m-1 col">
          <label className="form-label">Business</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("business")}
          />
          {formik.touched.business && formik.errors.business && (
            <div className="text-dark">{formik.errors.business}</div>
          )}
        </div>
       <div className="m-1 col">
          <label className="form-label">Income</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("income")}
          />
          {formik.touched.income && formik.errors.income && (
            <div className="text-dark">{formik.errors.income}</div>
          )}
        </div>
     
        </div>
        <h3 className="mb-1 pt-5">Residence Details</h3>   
        
       <div style={THEME.Centerflex}>
       <div className="m-1 col">
        <label className="form-label">Country:</label>
        <select
          className=" form-control me-1"
          name="country"
          value={formik.values.country}
          onChange={e => {
            formik.handleChange(e);
            handleCountryChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
        >
          <option value="" label="" />
          {countries.map(country => (
            <option key={country.name} value={country.name} label={country.name} />
          ))}
        </select>
        {formik.touched.country && formik.errors.country && (
          <div className="text-dark">{formik.errors.country}</div>
        )}
      </div>

      <div className="m-1 col">
        <label className="form-label">Province:</label>
        <select
          className=" form-control me-1"
          name="province"
          value={formik.values.province}
          onChange={e => {
            formik.handleChange(e);
            handleProvinceChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
          disabled={!formik.values.country}
        >
          <option value="" label="" />
          {provinces.map(province => (
            <option key={province} value={province} label={province} />
          ))}
        </select>
        {formik.touched.province && formik.errors.province && (
          <div className="text-dark">{formik.errors.province}</div>
        )}
      </div>

      <div className="m-1 col">
        <label className="form-label">City:</label>
        <select
          className=" form-control me-1"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.province}
        >
          <option value="" label="" />
          {cities.map(city => (
            <option key={city} value={city} label={city} />
          ))}
        </select>
        {formik.touched.city && formik.errors.city && (
          <div className="text-dark">{formik.errors.city}</div>
        )}
      </div>
      </div>
      <p>Not In List ? <input type="checkbox" onChange={()=>setothercity(!othercity)}/></p>
          <div className="row" style={THEME.ROW}>
          {othercity!==false&&<div className="col">
                <label htmlFor="country">Country</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
                {formik.touched.country && formik.errors.country && (
                  <div style={{ color: "red" }}>{formik.errors.country}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="province">Province</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="province"
                  name="province"
                  value={formik.values.province}
                  onChange={formik.handleChange}
                />
                {formik.touched.province && formik.errors.province && (
                  <div style={{ color: "red" }}>{formik.errors.province}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="city">city</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && (
                  <div style={{ color: "red" }}>{formik.errors.city}</div>
                )}
              </div>}
              </div>

      
     
       <div style={THEME.ROW}>
        <div className="m-1">
          <label className="form-label">Belongs</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("belongs")}
          />
          {formik.touched.belongs && formik.errors.belongs && (
            <div className="text-dark">{formik.errors.belongs}</div>
          )}
        </div>
        
          <div className="m-1">
          <label className="form-label">Area</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("area")}
          />
          {formik.touched.area && formik.errors.area && (
            <div className="text-dark">{formik.errors.area}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Full Address</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("fulladdress")}
          />
          {formik.touched.fulladdress && formik.errors.fulladdress && (
            <div className="text-dark">{formik.errors.fulladdress}</div>
          )}
        </div></div>
        <h3 className="mb-1 pt-5">Family Details</h3>
       <div style={THEME.ROW}><div className="m-1">
          <label className="form-label">Family Status</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("familystatus")}
          />
          {formik.touched.familystatus && formik.errors.familystatus && (
            <div className="text-dark">{formik.errors.familystatus}</div>
          )}
        </div>
       
        <div className="m-1">
          <label className="form-label">Home Type</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("hometype")}
          />
          {formik.touched.hometype && formik.errors.hometype && (
            <div className="text-dark">{formik.errors.hometype}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Home Size</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("homesize")}
          />
          {formik.touched.homesize && formik.errors.homesize && (
            <div className="text-dark">{formik.errors.homesize}</div>
          )}
        </div>
       </div>
       <div style={THEME.ROW}><div className="m-1">
          <label className="form-label">Father</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("father")}
          />
          {formik.touched.father && formik.errors.father && (
            <div className="text-dark">{formik.errors.father}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Mother</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("mother")}
          />
          {formik.touched.mother && formik.errors.mother && (
            <div className="text-dark">{formik.errors.mother}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Brothers</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("brothers")}
          />
          {formik.touched.brothers && formik.errors.brothers && (
            <div className="text-dark">{formik.errors.brothers}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Sisters</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("sisters")}
          />
          {formik.touched.sisters && formik.errors.sisters && (
            <div className="text-dark">{formik.errors.sisters}</div>
          )}
        </div>
     </div>
     <h3 className="mb-1 pt-5">Requirements</h3>
       <div style={THEME.ROW}><div className="m-1">
          <label className="form-label"> Age From</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqage")}
          />
          {formik.touched.reqage && formik.errors.reqage && (
            <div className="text-dark">{formik.errors.reqage}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Age To</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqageto")}
          />
          {formik.touched.reqageto && formik.errors.reqageto && (
            <div className="text-dark">{formik.errors.reqageto}</div>
          )}
        </div>
        </div>
        <div style={THEME.ROW}>

        <div className="m-1">
  <label className="form-label">Marital Status</label>
  <select
    className=" form-control"
    {...formik.getFieldProps("reqmaritalstatus")}
  >
    <option value="" label="" />
    <option value="single" label="Single Never Merried" />
    <option value="nikkahbreak" label="Nikkah Break" />
    <option value="divorced" label="Divorced" />
    <option value="divorcedchild" label="Divorced (With Childrens)" />
    <option value="widowed" label="Widowed" />
    <option value="separated" label="Separated" />
    <option value="Second Marriage" label="Second Marriage" />
  </select>
  {formik.touched.reqmaritalstatus && formik.errors.reqmaritalstatus && (
    <div className="text-dark">{formik.errors.reqmaritalstatus}</div>
  )}
</div>
        
        <div className="m-1">
          <label className="form-label">Maslak</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqmaslak")}
          />
          {formik.touched.reqmaslak && formik.errors.reqmaslak && (
            <div className="text-dark">{formik.errors.reqmaslak}</div>
          )}
        </div>
       </div>
       <div style={THEME.ROW}>  <div className="m-1">
          <label className="form-label">Height</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqheight")}
          />
          {formik.touched.reqheight && formik.errors.reqheight && (
            <div className="text-dark">{formik.errors.reqheight}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label">Family Status</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqfamilystatus")}
          />
          {formik.touched.reqfamilystatus && formik.errors.reqfamilystatus && (
            <div className="text-dark">{formik.errors.reqfamilystatus}</div>
          )}
        </div>
        <div className="m-1">
          <label className="form-label"> Cast</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqcast")}
          />
          {formik.touched.reqcast && formik.errors.reqcast && (
            <div className="text-dark">{formik.errors.reqcast}</div>
          )}
        </div>
        
       </div>
       <div style={THEME.ROW}>         <div className="m-1">
          <label className="form-label"> Education</label>
          <input
            type="text"
            className=" form-control"
            {...formik.getFieldProps("reqeducation")}
          />
          {formik.touched.reqeducation && formik.errors.reqeducation && (
            <div className="text-dark">{formik.errors.reqeducation}</div>
          )}
        </div>
        <div className="m-1">
        <label className="form-label"> Country:</label>
        <select
          className=" form-control me-1"
          name="reqcountry"
          value={formik.values.reqcountry}
          onChange={e => {
            formik.handleChange(e);
            handlereqCountryChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
        >
          <option value="" label="" />
          {countries.map(country => (
            <option key={country.name} value={country.name} label={country.name} />
          ))}
        </select>
        {formik.touched.reqcountry && formik.errors.reqcountry && (
          <div className="text-dark">{formik.errors.reqcountry}</div>
        )}
      </div>
</div>
       <div style={THEME.ROW}>
        
      
      <div className="m-1 col">
        <label className="form-label">Province:</label>
        <select
          className="col  form-control me-1"
          name="reqprovince"
          value={formik.values.reqprovince}
          onChange={e => {
            formik.handleChange(e);
            handlereqProvinceChange(e.target.value);
          }}
          onBlur={formik.handleBlur}
          disabled={!formik.values.reqcountry}
        >
          <option value="" label="" />
          {reqprovinces.map(province => (
            <option key={province} value={province} label={province} />
          ))}
        </select>
        {formik.touched.reqprovince && formik.errors.reqprovince && (
          <div className="text-dark">{formik.errors.reqprovince}</div>
        )}
      </div>

      <div className="m-1 col">
        <label className="form-label">City:</label>
        <select
          className="col  form-control me-1"
          name="reqcity"
          value={formik.values.reqcity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.reqprovince}
        >
          <option value="" label="" />
          {reqcities.map(city => (
            <option key={city} value={city} label={city} />
          ))}
        </select>
        {formik.touched.reqcity && formik.errors.reqcity && (
          <div className="text-dark">{formik.errors.reqcity}</div>
        )}
      </div>
        </div>
        
        <p>Not In List ? <input type="checkbox" onChange={()=>setotherreqcity(!otherreqcity)}/></p>
          <div  style={THEME.ROW}>
         
        
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqcountry">Country</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="reqcountry"
                  name="reqcountry"
                  value={formik.values.reqcountry}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqcountry && formik.errors.reqcountry && (
                  <div style={{ color: "red" }}>{formik.errors.reqcountry}</div>
                )}
              </div>}
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqprovince">Province</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="reqprovince"
                  name="reqprovince"
                  value={formik.values.reqprovince}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqprovince && formik.errors.reqprovince && (
                  <div style={{ color: "red" }}>{formik.errors.reqprovince}</div>
                )}
              </div>}
          {otherreqcity!==false&&<div className="col">
                <label htmlFor="reqcity">city</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="reqcity"
                  name="reqcity"
                  value={formik.values.reqcity}
                  onChange={formik.handleChange}
                />
                {formik.touched.reqcity && formik.errors.reqcity && (
                  <div style={{ color: "red" }}>{formik.errors.reqcity}</div>
                )}
              </div>}
              </div>

       <div style={THEME.ROW}> 
       <div className="m-1 col">
  <label className="form-label">Description</label>
  <textarea
    rows="3"
    className=" form-control me-1"
    {...formik.getFieldProps("description")}
  />
  {formik.touched.description && formik.errors.description && (
    <div className="text-dark">{formik.errors.description}</div>
  )}
</div>
        </div>
       
  
                
  
        
        
        
<div className="row"><button type="submit" className="btn btn-dark text-light m-5 col ">
          Save
        </button></div>
        
      </form>
    </div>
    </>
  );
};

export default UpdateUser;