// Footer.js
import React from "react";
import logo from "../assets/1 (32).jpg";
import THEME from "../theme";
import { Link } from "react-router-dom";

const CustomFooter = () => {
  return (
    <footer
      style={THEME.FooterStyle}
      className="text-center text-white text-lg-start"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <img
              className="img-fluid"
              src={logo}
              style={{ width: "90%", height: "85%" }}
            />
          </div>

          {/* Navigation Section */}
          <div className="col-lg-6 col-md-6">
            <p style={{ textAlign: "left", fontSize:14 }}>
              Welcome to SK Matrimonial. A web application project by SK GROUPS
              of International Match Makers. For fastest and better working of
              each and every match maker. This web application is far more
              better than thousands of WhatsApp Matrimonial Groups.
              <br />
              <br />
              Join SKMatrimonial today and start your journey to successful
              match making in a fastest way.
              <br />
              
            </p>
          </div>
        
        </div>
      </div>
      {/* Copyright Section */}
      <div className="ps-3 pe-3 row text-dark bg-light">
        <div className="col mt-3 text-center text-dark bg-light">
          <h6 className="">Copyright</h6>
          <p>
            SKMatrimonial {new Date().getFullYear()}
            <br /> All rights reserved.
          </p>
        </div>
        <div className="col mb-4 mt-3 text-center">
          <h6 className="">Powered by</h6>
          <Link to="https://tahiriservices.com" className="text-dark btn">
            <p>TahiriServices.com</p>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
