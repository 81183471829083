import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "./AuthContext";
import THEME, { WEB } from "../theme";
import logo from "../assets/1 (1).jpg";
import GetUsers from "./GetUsers";
const CustomNavbar = ({ counts }) => {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const GetUser = () => {
    const users = localStorage.getItem("user");
    const data = JSON.parse(users);

    if (data) {
      setUser(data);
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  const { logout } = useAuth();
  const avatar = `${WEB.Backend}node/${user && user.avatar}`;

  const handleLogout = () => {
    // Call the logout function from the authentication context
    logout();
  };

  const handleUpdateClick = (user) => {
    navigate(`/updateuser/${user.userId}`, {
      state: { clientData: user },
    });
  };
  const handleverifyClick = (user) => {
    navigate(`/verifyuser/${user.userId}`, {
      state: { clientData: user },
    });
  };
  const handleviewClick = (user) => {
    navigate(`/allusers/${user.userId}`, {
      state: { clientData: user },
    });
  };

  const handleUpdateAdminClick = (user) => {
    navigate(`/updateadmin/${user.userId}`, {
      state: { clientData: user },
    });
  };

 



  return (<>
  {user && <nav className="navbar navbar-expand-lg navbar-light bg-light text-center">
  <div className="container text-center">
         <Link className="navbar-brand" style={THEME.ROW} to="/admindashboard">
           <img src={logo} alt="Logo" width="50vw" height="50vw" className="d-inline-block me-2" />
           <h1
             className="mt-2"
             style={{
               color: THEME.COLORS.PRIMARY,
               fontWeight: "bold",
               fontSize: "16px",
             }}
           >
            SK MATRIMONIALS
           </h1>
        </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <FontAwesomeIcon icon="bars" />{/* <span className="navbar-toggler-icon"></span> */}
    </button>
    <div className="collapse navbar-collapse text-center ms-5" id="navbarTogglerDemo02">
      <ul className="navbar-nav me-auto">
     
      </ul>
      <form className="text-center">
                           
      <div className="navbar-nav text-center ">
      <div className="row">
      <div className="me-5 mt-2 col" >
      <div className="row">
      
               
                
      </div>
      
      </div>
      </div>
      <div className="row">
      

      <div className="me-5 mt-2 col">
      <Link to="/admindashboard" className="btn btn-dark rounded text-light nav-link">Admin Home</Link>
      </div>
</div>
<div className="row">
   
   <div className="me-5 mt-2 col">
    <Link to="/allclients" className="btn btn-dark rounded text-light nav-link">All Clients</Link>
   </div>

</div>
      <div className="row">
      
      <div className="me-5 mt-2 col" >
      <div className="row">
    <div className="col" onClick={() => handleUpdateAdminClick(user)}>
                  <Link className="btn btn-dark rounded text-light nav-link">My Profile</Link>
                </div>
      </div>
        </div>
        </div>
    
    
     
      
      <div className="row">
      <div className="me-5 mt-2 col" onClick={handleLogout}>
                    <Link to="/" className="btn btn-dark rounded text-light nav-link">Logout</Link>
                  </div>
        </div>
        <div className="row">
      <div className="me-5 mt-2 col">
                    <Link to="/" className="btn btn-success rounded text-light nav-link">Download App</Link>
                  </div>
        </div>
        
                  
                  
                  
      </div>
        
      </form>
    </div>
  </div>
  <script src="../assets/bootstrap.js"></script>
    <script src="../assets/popper.min.js"></script>
    <script src="../assets/jquery-3.6.4.js"></script>
</nav>}</>
);
 };

 export default CustomNavbar;