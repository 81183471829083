import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "./Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import theme,{WEB} from "../theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { countries, simulatedCities } from "./custom/LocationData"
import SelectCode from './custom/Select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const validationSchema = Yup.object().shape({
  phone: Yup.string().required("Phone is required"),
  password: Yup.string().required("Password is required"),

});

const UpdateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state && location.state.clientData;
  const userId = user.userId;
  const [isEditing, setIsEditing] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [othercity, setothercity] = useState(false);


  const formik = useFormik({
    initialValues: {
      name: user.name,
      ccode: user.ccode,
      phone: user.phone,
      email: user.email,
      password: user.password,
      city: user.city,
      province: user.province,
      country: user.country,
      role: user.role,
      status: user.status,
      gender: user.gender,
      verified: user.verified,
      avatar: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      // Upload avatar first if provided
      if (values.avatar) {
        await uploadAvatar(values.avatar);
      }

      // Update user information
      axios
        .put(`${WEB.Backend}node/updateuser/${userId}`, values)
        .then((response) => {
          console.log(response.data.message);
          toast.success(response.data.message);
         
          navigate("/admindashboard");
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          toast.error("Something failed. Please try again.");
        });
    },
  });

  const handleFileChange = (e) => {
    formik.setFieldValue("avatar", e.target.files[0]);
  };

  const uploadAvatar = async (avatar) => {
    try {
      const formData = new FormData();
      formData.append("avatar", avatar);

      const response = await axios.post(
        `${WEB.Backend}node/updateimage/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Avatar uploaded successfully:", response.data.message);
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };
  //County Province City
  const handleCountryChange = (selectedCountry) => {
    const selectedCountryObj = countries.find(
      (country) => country.name === selectedCountry
    );

    if (selectedCountryObj) {
      setProvinces(selectedCountryObj.provinces || []);
      setCities([]);
      formik.setFieldValue("province", "");
      formik.setFieldValue("city", "");
    }
  };

  const handleProvinceChange = (selectedProvince) => {
    
    setCities(simulatedCities[selectedProvince] || []);
    formik.setFieldValue("city", "");
  };
  const handleAvatarClick = () => {
    document.getElementById("avatarInput").click();
  };

  return (
    <>
      <Navbar />
      <div className="container ">
        <div className="container bg-light mb-3 mt-2 text-dark">
          <div className="container col pt-2">
           
             <div className="d-flex row">
          
            <div className="" style={{justifyContent:'space-between',display:'flex'}}>
<div>
                {isEditing ? (
                  
                  
                  
                  
                  <div onClick={handleAvatarClick}>
                  {formik.values.avatar ?<img
                  className="rounded"
                    style={{ height: "5rem", width: "5rem" }}
                    src={URL.createObjectURL(formik.values.avatar)}
                  />:<img
                  style={{ height: "5rem", width: "5rem" }}
                  src={`${WEB.Backend}node/uploads/${user.avatar}`}
                />}
                  <input hidden  id="avatarInput"
                    className="form-control text-dark  form-control" type="file" name="avatar " onChange={handleFileChange} /></div>
                ) : (
                  <img
                    style={{ height: "5rem", width: "5rem" }}
                    src={`${WEB.Backend}node/uploads/${user.avatar}`}
                  />
                )}
                </div>
                <div className="container"><Link to={`https://wa.me/${user.ccode + user.phone}?text= Hi ${user.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="col  btn btn-success m-2"><FontAwesomeIcon style={{fontSize:24}} icon={faWhatsapp} /> User</button>
            </Link>
          </div>
              </div>
              
            </div>
            
          <h5 style={{ color: 'white' }}>Personal Details</h5>
        
            <form onSubmit={formik.handleSubmit}>
              {/* Render input fields for each user property */}
            
            
               {/* Manual rendering of each input field */}
              <div className="row">
              <div className="col">
                <label htmlFor="name">Name</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div style={{ color: "red" }}>{formik.errors.name}</div>
                )}
              </div>
              <div className="col">
              <label htmlFor="gender">Gender:</label>
                  <select
                    className=" form-control"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="male" label="Male" />
                    <option value="female" label="Female" />
                  </select>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="text-dark">{formik.errors.gender}</div>
                  )}
                </div>
              </div>
              <div className="row">
              <div className="col-5  text-dark">
                <label htmlFor="ccode">Country Code</label>
                <SelectCode
                  className=" text-dark  form-control"
                  id="ccode"
                  name="ccode"
                  value={formik.values.ccode}
                  onChange={formik.handleChange}
                />
                {formik.touched.ccode && formik.errors.ccode && (
                  <div style={{ color: "red" }}>{formik.errors.ccode}</div>
                )}
              </div>
     
              <div className="col">
                <label htmlFor="phone">Phone</label>
                <input
                    className="text-dark  form-control"
                  type="number"
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div style={{ color: "red" }}>{formik.errors.phone}</div>
                )}
              </div>
              </div>
              <div className="col">
                <label htmlFor="email">email</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div style={{ color: "red" }}>{formik.errors.email}</div>
                )}
              </div>
              <div className="col">
                <label htmlFor="password">password</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password && (
                  <div style={{ color: "red" }}>{formik.errors.password}</div>
                )}
              </div>
              <div style={theme.ROW}>
                <div className="col m-1">
                  <label className="form-label">Country:</label>
                  <select
                    className="   form-control"
                    name="country"
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleCountryChange(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value={formik.values.country} label={formik.values.country} />
                    {countries.map((country) => (
                      <option
                        key={country.name}
                        value={country.name}
                        label={country.name}
                      />
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className="text-dark">{formik.errors.country}</div>
                  )}
                </div>

                <div className="col m-1">
                  <label className="form-label">Province:</label>
                  <select
                    className="  form-control"
                    name="province"
                    value={formik.values.province}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleProvinceChange(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    disabled={!formik.values.country}
                  >
                    <option value={formik.values.province} label={formik.values.province} />
                    {provinces.map((province) => (
                      <option
                        key={province}
                        value={province}
                        label={province}
                      />
                    ))}
                  </select>
                  {formik.touched.province && formik.errors.province && (
                    <div className="text-dark">{formik.errors.province}</div>
                  )}
                </div>

                <div className="col m-1">
                  <label className="form-label">City:</label>
                  <select
                    className="  form-control"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!formik.values.province}
                  >
                    <option value={formik.values.city} label={formik.values.city} />
                    <option><div onClick={()=>setothercity(true)}>Other</div></option>
                    {cities.map((city) => (
                      <option key={city} value={city} label={city} />
                    ))}
                  </select>
                  {formik.touched.city && formik.errors.city && (
                    <div className="text-dark">{formik.errors.city}</div>
                  )}
                </div>
              </div>
          <p>Not In List ? <input type="checkbox" onChange={()=>setothercity(!othercity)}/></p>
          <div className="row" style={theme.ROW}>
          {othercity!==false&&<div className="col">
                <label htmlFor="country">Country</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                />
                {formik.touched.country && formik.errors.country && (
                  <div style={{ color: "red" }}>{formik.errors.country}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="province">Province</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="province"
                  name="province"
                  value={formik.values.province}
                  onChange={formik.handleChange}
                />
                {formik.touched.province && formik.errors.province && (
                  <div style={{ color: "red" }}>{formik.errors.province}</div>
                )}
              </div>}
          {othercity!==false&&<div className="col">
                <label htmlFor="city">city</label>
                <input
                    className="text-dark  form-control"
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city && (
                  <div style={{ color: "red" }}>{formik.errors.city}</div>
                )}
              </div>}
              </div> 
            
              <div className="row" style={theme.ROW}>
              <div className="col">
                    <label className="form-label">Role:</label>
                    <select
                      className=" form-control col text-dark bg-light"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="matchmaker" label="Match Maker" />
                      <option value="user" label="User" />
                      <option value="admin" label="Admin" />
                    </select>
                    {formik.touched.role && formik.errors.role && (
                      <div className="text-dark">{formik.errors.role}</div>
                    )}
                  </div>
                    <div className="col">
                     <label className="form-label">Status:</label>
                     <select
                       className=" form-control col text-dark bg-light"
                       name="status"
                       value={formik.values.status}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                     >
                       <option value="enabled" label="Enabled" />
                       <option value="disabled" label="Disabled" />
                     </select>
                     {formik.touched.status && formik.errors.status && (
                       <div className="text-dark">{formik.errors.status}</div>
                     )}
                 </div>
                 <div className="col">
                     <label className="form-label">Verified ?:</label>
                     <select
                       className=" form-control col text-dark bg-light"
                       name="verified"
                       value={formik.values.verified}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                     >
                       <option value={formik.values.verified} label={formik.values.verified} />
                       <option value="yes" label="Yes" />
                       <option value="no" label="No" />
                     </select>
                     {formik.touched.verified && formik.errors.verified && (
                       <div className="text-dark">{formik.errors.verified}</div>
                     )}
                 </div>
              </div>
                
            </form>
       
          
            <div className="row">
              <button
                className="col  btn btn-dark text-light m-3"
                onClick={() => {
                  if (isEditing) {
                    formik.handleSubmit();
                  } else {
                    setIsEditing(true);
                  }
                }}
              >
                {isEditing ? "Save Changes" : "Edit Profile"}
              </button>
            </div>
          </div></div>
     
        </div>
      
    </>
  );
};

export default UpdateUser;
                
  