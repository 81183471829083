import React, { useEffect,useState } from 'react';
import Navbar from './Navbar';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios'
import theme,{WEB} from '../theme';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Dashboard() {
  const [user, setUser] = useState();
  const [clients, setClientData] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [filters, setFilters] = useState({
    city: "",
    country: "",
    maritalStatus: "",
    name: "",
    ageFrom: "",
    ageTo: "",
    gender: "",
    province: "",
    education: "",
    role: "",
  });


  const navigate = useNavigate();
  
   
  const handleFilterChange = (filterType, value) => {
    setFilters({ ...filters, [filterType]: value });
    setShowResults(true);
  };

  const filteredClients = clients
    ? clients.filter((client) => {
      const isAgeInRange =
          (client.age >= parseInt(filters.ageFrom) || !filters.ageFrom) &&
          (client.age <= parseInt(filters.ageTo) || !filters.ageTo);
        return (
          client.city&&client.city.toLowerCase().includes(filters.city.toLowerCase()) &&
          client.country&&client.country
            .toLowerCase()
            .includes(filters.country.toLowerCase()) &&
            client.maritalstatus&&client.maritalstatus
            .toLowerCase()
            .includes(filters.maritalStatus.toLowerCase()) &&
          client.name&&client.name.toLowerCase().includes(filters.name.toLowerCase()) &&
          isAgeInRange &&
          client.gender&&client.gender.toLowerCase() === filters.gender.toLowerCase() &&
          client.province&&client.province.toLowerCase().includes(filters.province.toLowerCase())&&
          client.education&&client.education.toLowerCase().includes(filters.education.toLowerCase())&&
          client.role&&client.role.toLowerCase().includes(filters.role.toLowerCase())
        );
      })
    : null;
    const handleViewClick = client => {
      navigate(`/viewid/${client.clientid}`, { state: { clientData: client } });
    };


const GetUser=()=>{
const users = localStorage.getItem('user');
const data=JSON.parse(users)
if (data) {
  setUser(data);
  const userId = data && data.userId;
  const queryParams = new URLSearchParams(filters);
  fetch(`${WEB.Backend}node/clients/search?${queryParams}`)
  
    .then(response => response.json())
  
    .then(data2 => {
      setClientData(data2.data)
      console.log(data2.data)
    }
    )

    .catch(error => console.log(error));
   
}
 } 

  useEffect(() => {
   GetUser()
  }, []);
  
  useEffect(() => {
   GetUser()
  
  }, [filters]);
  
  useEffect(() => {
   GetUser()
  
  }, [filters]);
  
  const genderOptions = ['Male', 'Female'];
  const maritalStatusOptions = ['Single', 'Married', 'Divorced'];

  return (
    <>  
      <div className="container bg-light" style={theme.Centerflex}>
<div className="m-3"style={theme.Center}>
<div className="row" style={{}}>
            
             <label style={{color:theme.COLORS.PRIMARY}}>Looking for:</label>
            <select
              className=" form-control col-2"
              value={filters.gender}
              onChange={(e) => handleFilterChange("gender", e.target.value)}
            >
              <option value=""></option>
              {genderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="row" style={{}}>
             <label style={{color:theme.COLORS.PRIMARY}}>Age From:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.ageFrom}
              onChange={(e) => handleFilterChange("ageFrom", e.target.value)}
            />
          </div>
          <div className="row" style={{}}>
            
            
             <label style={{color:theme.COLORS.PRIMARY}}>Marital Status:</label>
            <select
              className=" form-control col-2"
              value={filters.maritalStatus}
              onChange={(e) =>
                handleFilterChange("maritalStatus", e.target.value)
              }
            >
              <option value=""></option>
              <option value="single" label="Single Never Merried" />
              <option value="nikkahbreak" label="Nikkah Break" />
              <option value="divorced" label="Divorced" />
              <option value="divorcedchild" label="Divorced (With Childrens)" />
              <option value="widowed" label="Widowed" />
              <option value="separated" label="Separated" />
              <option value="Second Marriage" label="Second Marriage" />
            </select>
          </div>
          
          <div className="row" style={{}}>
            
            <label style={{color:theme.COLORS.PRIMARY}}>Profile By:</label>
           <select
             className=" form-control col-2"
             value={filters.role}
             onChange={(e) => handleFilterChange("role", e.target.value)}
           >
             <option value=""></option>
             <option value="user">Candidate</option>
             <option value="matchmaker">Matchmaker</option>
             
           </select>
         </div>
          <div className="row" style={{}}>
             <label style={{color:theme.COLORS.PRIMARY}}>Province:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.province}
              onChange={(e) => handleFilterChange("province", e.target.value)}
            />
          </div>
          
          </div>
        
          
<div className="m-3"style={theme.Center}>
<div className="row" >
             <label style={{color:theme.COLORS.PRIMARY}}>Name:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.name}
              onChange={(e) => handleFilterChange("name", e.target.value)}
            />
          </div>

          <div className="row" style={{}}>
             <label style={{color:theme.COLORS.PRIMARY}}>Age To:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.ageTo}
              onChange={(e) => handleFilterChange("ageTo", e.target.value)}
            />
        </div>
        <div className="row" style={{}}>
            
            <label style={{color:theme.COLORS.PRIMARY}}>Education:</label>
           <input
             type="text"
             className=" form-control col-2"
             value={filters.education}
             onChange={(e) => handleFilterChange("education", e.target.value)}
           />
         </div>
          <div className="row" style={{}}>
            
             <label style={{color:theme.COLORS.PRIMARY}}>City:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.city}
              onChange={(e) => handleFilterChange("city", e.target.value)}
            />
          </div>
          
         
          
          
          <div className="row" style={{}}>
            
             <label style={{color:theme.COLORS.PRIMARY}}>Country:</label>
            <input
              type="text"
              className=" form-control col-2"
              value={filters.country}
              onChange={(e) => handleFilterChange("country", e.target.value)}
            />
          </div>
</div>
</div>

        
        
     

      {showResults && (
        <div>
          <div className='text-light' style={theme.Center}>
            <h2 className="m-3">Search Clients</h2>
            <p className="m-3">
              Total Clients :
              {clients && clients.length > 0 ? clients.length : "No Clients"}
            </p>
          </div>
          <div style={theme.Center}>
            <div
              className="text-center col bg-light text-dark  p-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 60,
              }}
            >
              <div className="col-2">
                 <label>Photo</label>
              </div>
              <div className="col-2">
                 <label >Name</label>
              </div>
              <div className="col-2">
                 <label >Status</label>
              </div>
              <div className="col-2">
                 <label >Age</label>
              </div>

              <div className="col-2">
                 <label >City</label>
              </div>
             
            </div>
          </div>
          {filteredClients &&
            filteredClients.map((client) => (
              <>
                <div style={theme.Center}>
                  <div onClick={() => handleViewClick(client)}
                    className="text-center bg-light text-dark"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 2,
                      borderColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 100,
                    }}
                  >
                    <div className='col-3'><img style={{height:'80px',width:'60px'}} src={`${WEB.Backend}node/uploads/${client.avatar}`}/></div>
                    <div className="col-2">
                      <p style={{color:"black"}}>{client.name}</p>
                    </div>
                    <div className="col-2">
                      <p style={{color:"black"}}>{client.maritalstatus}</p>
                    </div>
                    <div className="col-2">
                      <p style={{color:"black"}}>{client.age}</p>
                    </div>

                    <div className="col-2">
                      <p style={{color:"black"}}>{client.city}</p>
                    </div>
                   
                  </div>
                </div>
              </>
            ))}
        </div>
      )}
    </>

   
//       <button className="m-1 col-5  btn btn-secondary"onClick={() => {
//     const textToCopy = `Profile from skmatrimonial.com is this available?\n\nProposal Type: ${client.proptype}\nSharing Type: ${client.shareamount}\nAmount In Rupees: ${client.amount}\nProposal Priority: ${client.propprio}\nProposal For: ${client.propfor}\n\nMatchMaker Number:${client.ccode} ${client.contact}\n\nGender: ${client.gender}\nName: ${client.name}\nAge: ${client.age}\nHeight: ${client.height}\nMarital Status: ${client.maritalstatus}\nDivorce Reason: ${client.divorcereason}\nChildren: ${client.children}\nEducation: ${client.education}\nCast: ${client.cast}\nMaslak: ${client.maslak}\nFamily Status: ${client.familystatus}\nFather: ${client.father}\nMother: ${client.mother}\nBrothers: ${client.brothers}\nSisters: ${client.sisters}\nArea: ${client.area}\nFull Address: ${client.fulladdress}\nCountry: ${client.country}\nProvince: ${client.province}\nCity: ${client.city}\n\nRequirement Details:\nAge ${client.reqage}\nCast: ${client.reqcast}\nMaslak: ${client.reqmaslak}\nEducation: ${client.reqeducation}\nHeight: ${client.reqheight}\nMarital Status: ${client.reqmaritalstatus}\nFamily Status: ${client.reqfamilystatus}\nCountry: ${client.reqcountry}\nProvince: ${client.reqprovince}\nCity: ${client.reqcity}`;

//     navigator.clipboard.writeText(textToCopy)
//       .then(() => {
//         console.log('Text copied to clipboard');
//       })
//       .catch((err) => {
//         console.error('Unable to copy text to clipboard', err);
//       });
//   }}>Copy Data</button>

      
      
//     </div> 
    
//     </div>
    
//   </>
// ))}   </div>}</div>
  );
}
